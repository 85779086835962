import React, { useEffect, useState } from "react"
import { setPreciosYDescuento } from "../../helpers/calcularVenta";
import { decimalAdjust, TIPO_STOCK } from "../../Global";


export function Combo({ combo, onChooseCombo }) {
    return <>
        <div className="lista-producto-promo-content" onClick={onChooseCombo}>
            <div className="nombre-prunitario4">
                <p className="nombre-producto4">{combo.Nombre}</p>
                <p className="precio-producto4">{combo.Moneda} {decimalAdjust("floor", combo.Precio, -2)}</p>
                <p className='stock-producto4'>{combo.stock}</p>
            </div>
        </div>
    </>
}


export function Product({ product, onChooseProduct }) {
    const truncateText = (text, maxLength) => {
        return text.length > maxLength ? text.substring(0, maxLength) : text;
    };

    return <>
        <div className="lista-producto-promo-content" onClick={onChooseProduct}>
            <div className="nombre-prunitario4">
                <p className="nombre-producto4">{truncateText(`${product.descripcion} ${product.unidadMedida}`, 50)}</p>
                <p className="precio-producto4">{product.moneda} {decimalAdjust("floor", product.precio, -2)}</p>
                <p className='stock-producto4'>{product.stock}</p>
            </div>
        </div>
    </>
}


export function ProductPicker({ query, onChooseProduct }) {
    const [products, setProducts] = useState([]);
    const [combos, setCombos] = useState([]);

    useEffect(() => {
        fetchProductos('');
        fetchCombos("");
    }, [])

    useEffect(() => {
        fetchProductos(query);
        fetchCombos(query);
    }, [query])

    const fetchCombos = async (q) => {
        const req = await fetch(`/api/combos`);
        const combos = await req.json();

        if (q == "") {
            setCombos(combos);
        } else {
            setCombos(combos.filter(c => c.Nombre.includes(q)));
        }
    }

    const fetchProductos = async (q) => {
        const req = await fetch(`/api/preVentas/?search=${q}&idTipoStock=${TIPO_STOCK.CON_COMPROBANTE}`);
        const data = await req.json();
    
        const products = data.productos.filter(d => {
            const itemCalculado = setPreciosYDescuento(d);
            return itemCalculado.PrecioVenta > 0;
        }).map((d) => {
            const itemCalculado = setPreciosYDescuento(d);
            return {
                ...d,
                Precio: itemCalculado.PrecioVenta,
                PrecioReferencial: itemCalculado.PrecioVenta,
                Gratuito: parseInt(d.Gratuito),
            };
        });
    
        setProducts(products);
    };

    const onChooseCombo = (productos) => {
        onChooseProduct(...productos)
    }

    return <>
      <div className="fijado2">
          <div className="titulo-productos">
              <p>PRODUCTO/PRESENTACION</p>
              <p>PRECIO</p>
              <p>STOCK</p>
          </div>
      </div>
      <div className="productos-promo-scroll">
          <div className="lista-producto-promo-general">
            { combos.map((c, i) => <Combo key={i} combo={c} onChooseCombo={onChooseCombo} />)}
            { products.map((p, i) => <Product key={i} product={p} onChooseProduct={() => onChooseProduct(p)}  />)}
          </div>
      </div>
    </>
}