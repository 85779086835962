import React from "react";
import "react-moment";
import _ from 'lodash';
import '../styles/PreVenta.css';
import ModalCliente from '../clientes/ClienteModal';
import Modal from "../../componentes/Modal.js";
import ClienteNuevo from '../clientes/ClientesNuevo';
import BuscarClientePorDoc from './BuscarClientePorDoc'
import {
    notificationBoletaVarios,
    notificationCantidadNegativa,
    notificationClienteNoExiste,
    notificationConfigBoletaRUC,
    notificationDescuentoGratuito,
    notificationDescuentoMayorTotal,
    notificationFacturaRUC,
    notificationNoHayProducto,
    notificationPreventaVacia,
    notificationProdValeInDetalle,
    notificationStock0,
    notificationStockSinComprobante
} from './PreVentaNotify'
import {
    FindPrecioEspecial,
    FindPrecioFamiliar,
    FindPrecioMenor,
    FindPrecioPorMayor,
    GetPrecioCosto, getPrecioPorMayor
} from './PreciosPreventa'
import swal from "sweetalert";
import PreventaAlmacenes from "./PreventaAlmacenes"
import {
    BOLETA,
    TIPO_STOCK,
    ESTADO_PREVENTA,
    FACTURA,
    RUC,
    VALE,
    NUMBER_KEYS,
    calcularTotales, decimalAdjust, calcTotal
} from "../../Global.js";
import PreventaModalDescuentos from "./PreventaModalDescuentos.js";
import {
    setPreciosYDescuento,
    setValorUnitario
} from "../../helpers/calcularVenta.js";
import ModalBuscarCliente from "./ModalBuscarCliente.js";
import DetallesProductoPreventa from "./DetallesProductoPreventa";
import $ from "jquery";
import Noty from "noty";
import ListaProductosPreventa from "./ListaProductosPreventa";
import {notificarError, notificarMsg} from "../Almacenes/AlmacenNotify";
import {isNumber, safe} from "../../helpers/utils";
import {ACTION, ENTITY} from "contatrib-utils";
import MagellanListener, { KILOGRAMO_UNIT_DESCRIPTION } from "../../helpers/MagellanListener";
import CombosService from "../../services/CombosService";
import matchSorter from "match-sorter";


class PreVentaEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            inputName: "",
            inputTeclado: {},
            item: {},
            loading: true,
            error: null,
            buscar: "",
            IdCliente: "",
            IdEmpresa: "",
            Estado: "",
            dataProductos: {
                productos: []
            },
            Documento: "",
            filtroTipoStock: 1,
            checkfiltroTipoStock: true,
            dataDetalle: {
                respuesta: []
            },
            detallePreventa: {
                detalleItem: []
            },
            itemsConTributo: [],
            dataCliente: [],
            modalClienteIsOpen: false,
            totalMonto: 0,
            Gravado: 0,
            Inafecto: 0,
            ICBPERPreventa: 0,
            Exonerado: 0,
            IGVPreventa: 0,
            Gratuitas: 0,
            IVAP: 0,
            ISC: 0,
            IdPreventa: "",
            IdAlmacen: null,
            Codigo: "ABC",
            IdMoneda: 1,
            TasaIGV: "",
            TasaICBPER: "",
            Total: 1,
            IdModalidadPago: 1,
            IdTipoDocumentoSunat: "",
            Alias: "",
            VenderConStock: "",
            BoletaRuc: "",
            venderConListaPrecios: "",
            RUSS: "",
            StockMasVendidos: [],
            StockPorCliente: [],
            almacenes: {
                results: []
            },
            statusModal: false,
            statusModalDescuento: false,
            statusModalSearchCliente: false,
            tipoLista: 1,
            ItemDescuento: "",
            detalles: [],
            searchDisabled: false,
            load: false,
            redondeo: 0,
            afectsIgv: [],
            canUpdatePrice: true,
            canUpdateTotal: false,
            cleanSearch: false,
            accessCode: "",
            magellanListenerIsAvailable: false,

            codigosValidacion: [],
            codigoValidacionInput: "",
            permisosDescuento: false,
            codigoValido: false,
            showModalValidacion: false,

            combos: [],
            filteredCombos: [],
        }

        this.combosService = new CombosService();

        this.onSecretKeyPress = this.onSecretKeyPress.bind(this);
        this.getProductos = this.getProductos.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.DocChange = this.DocChange.bind(this)
        this.GenerarPreVenta = this.GenerarPreVenta.bind(this)
        this.handleTipoComprobante = this.handleTipoComprobante.bind(this)
        this.AddProductoInList = this.AddProductoInList.bind(this)
        this.AgregarCantidadDet = this.AgregarCantidadDet.bind(this)
        this.RestarCantidadDet = this.RestarCantidadDet.bind(this)
        this.RemoveProductoInList = this.RemoveProductoInList.bind(this)
        this.PrecioChange = this.PrecioChange.bind(this)
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleOnCloseModal = this.handleOnCloseModal.bind(this)
        this.handleOpenModalDescuento = this.handleOpenModalDescuento.bind(this)
        this.handleOnCloseModalDescuento = this.handleOnCloseModalDescuento.bind(this)
        this.handleClickBuscar = this.handleClickBuscar.bind(this)
        this.handleCloseBuscar = this.handleCloseBuscar.bind(this)
        this.handleKeyUp = this.handleKeyUp.bind(this)
        this.AlmacenChange = this.AlmacenChange.bind(this)
        this.onChangeAfectGratuita = this.onChangeAfectGratuita.bind(this)
        this.AsignarValorDoc = this.AsignarValorDoc.bind(this)
        this.handleEnterKeyUp = this.handleEnterKeyUp.bind(this);
        this.onSecretKeyPress = this.onSecretKeyPress.bind(this);
        this.onBlurInputPrecio = this.onBlurInputPrecio.bind(this);
        this.onBlurCantidad = this.onBlurCantidad.bind(this);
        this.onBlurDescuento = this.onBlurDescuento.bind(this);
        this.removeDetVenta = this.removeDetVenta.bind(this);
        this.updateTipoDoc = this.updateTipoDoc.bind(this);
        this.checkCanUpdatePrice = this.checkCanUpdatePrice.bind(this);
        this.checkCanUpdateTotal = this.checkCanUpdateTotal.bind(this);
        this.onChangeTotal = this.onChangeTotal.bind(this);
        this.onBlurTotal = this.onBlurTotal.bind(this);
        this.setAfectsIgv = this.setAfectsIgv.bind(this);

        this.permisosDescuentoItem = this.permisosDescuentoItem.bind(this)
        this.openModalValidacion = this.openModalValidacion.bind(this)
        this.handleChangeCodigoDescuento = this.handleChangeCodigoDescuento.bind(this)
        this.handleCloseModalValidarDescuento = this.handleCloseModalValidarDescuento.bind(this)
        this.fetchCodigoValidacion = this.fetchCodigoValidacion.bind(this)
        this.validarCodigoUsuario = this.validarCodigoUsuario.bind(this);
        this.handleChangeCodigoAnular = this.handleChangeCodigoAnular.bind(this);
        this.fetchCombos = this.fetchCombos.bind(this);
        this.queryParams = new URLSearchParams(window.location.search)
    }

    componentDidMount = async () => {
        const IdPreventa = this.props.match.params.IdPreventa;
        this.getRUSS()
        const accessCode = await safe(() => this.props.location.search.replace("?code=", ""), "")
        this.setState({
            accessCode: accessCode
        })
        await this.fetchCombos();
        await this.fetchTipoAfectsGratuitas();
        await this.checkCanUpdatePrice();
        await this.checkCanUpdateTotal();
        await this.checkConfSearchProds();
        await this.fetchCodigoValidacion();
        await this.permisosDescuentoItem();
        
        MagellanListener.addSubscriber(this);

        this.getPreventaById(IdPreventa) // forgive me Martin Fowler  I won't refactor this
            .then(() => {
                this.TraerClientePorDoc(this.state.Documento)
                this.getProductos()
                    .then(() => {
                        this.getConfigBoletasRuc();
                        this.getAlmacenes()
                        this.getDetallePreventa(IdPreventa)
                            .then(() => {
                                this.getConfigVendeConStock()
                                this.getAlmacenes()
                                this.getConfigVendeConListaPrecios();
                            })
                    })
            })

    };

    async fetchCombos() {
        try {
            const combos = await this.combosService.fetchAll();
            this.setState({ combos });
        } catch (e) {
            console.error(e);
        }
    }

    async checkConfSearchProds() {
        this.setState({load: true})
        const res = await fetch(`/api/preVentas/getConfEmpresa/${52}`);
        if (res.ok) {
            let data = await res.json();
            this.setState({cleanSearch: String(data[0].Estado) === "1"})
        } else notificarError('No se pudo cargar la conf para alias');
        this.setState({load: false})
    }

    async checkCanUpdatePrice() {
        let res = await fetch('/api/preVentas/check-can-update-price');
        if (!res.ok)
            this.setState({canUpdatePrice: false})
    }

    async checkCanUpdateTotal() {
        let res = await fetch('/api/preVentas/check-can-update-total');
        if (res.ok)
            this.setState({canUpdateTotal: true})
    }

    async fetchTipoAfectsGratuitas() {
        try {
            let res = await fetch('/api/preVentas/afect-igv?query=')
            let json = await res.json();
            this.setState({
                afectsIgv: json
            })
        } catch (e) {
            this.setState({error: e})
        }
    }

    onMagellanChangeAvailability(availability) {
        this.setState({ magellanListenerIsAvailable: availability });
    }

    onRecieveCode(code) {
        this.setState({buscar: code}, this.handleCodeReading.bind(this))
    }

    async validarCodigoUsuario() {
        const codigoInput = this.state.codigoValidacionInput;
        const codigosUsuarios = this.state.codigosValidacion;

        this.setState({codigoValidacionInput: ""})
        const validCode = codigosUsuarios.some(c => c == codigoInput)

        if (!validCode) {
            notificarMsg('El codigo ingresado no es correcto.', 'warning')
            return;
        }
        
        notificarMsg("Validacion completada exitosamente.");
        this.setState({codigoValido: true});

        this.handleOpenModalDescuento(this.state.ItemDescuento, true)
        this.setState({showModalValidacion: false})
    }

    async fetchCodigoValidacion() {
        this.setState({loading: true});
        const req = await fetch('/api/usuarios/codigoConfirmacion/sucursal');
        const res = await req.json();

        try {
            if (req.ok)
                var codigos = res.map(c => c.Codigo)
            this.setState({codigosValidacion: codigos})
        } catch (e) {
            console.error(e)
        }

        this.setState({loading: false});
    }

    async permisosDescuentoItem() {
        try {
            const responseVal = await fetch('/api/usuarios/descuentoItem/validar');
            const data = await responseVal.json();
            this.setState({permisosDescuento: true})
        } catch (e) {
            this.setState({permisosDescuento: false})
        }
    }

    openModalValidacion(item) {
        this.setState({showModalValidacion: true})
    }

    handleChangeCodigoDescuento = (text) => {
        const value = text.target.value
        this.setState({codigoValidacionInput: value});
    }

    handleCloseModalValidarDescuento = (e) => {
        this.setState({
            showModalValidacion: false
        })
    }

    handleChangeCodigoAnular = (text) => {
        const value = text.target.value
        this.setState({codigoValidacionInput: value});
    }

    getConfigVendeConStock = async () => {
        this.setState({error: null});
        try {
            /************* 9 = CONFIG. PERMITIR VENDER SIN STOCK ************* */
            const response = await fetch(`/api/preVentas/getConfEmpresa/${9}`);
            const data = await response.json();
            this.setState({VenderConStock: String(data[0].Estado) === "0"})
        } catch (error) {
            this.setState({error: error});
        }
    }

    getConfigBoletasRuc = async () => {
        this.setState({error: null});
        try {
            /************* 45 = CONFIG. PERMITIR VENDER BOLETA CON RUC ************* */
            const response = await fetch(`/api/preVentas/getConfEmpresa/${45}`);
            const data = await response.json();
            this.setState({BoletaRuc: data[0].Estado})
        } catch (error) {
            this.setState({error: error});
        }
    }

    getConfigVendeConListaPrecios = async () => {
        this.setState({error: null});
        try {
            /************* 2 = CONFIG. PERMITE VER LA LISTA DE PRECIOS EN EL DETALLE (HOVER) ************* */
            const response = await fetch(`/api/preVentas/getConfEmpresa/${2}`);
            const data = await response.json();
            //console.log(data[0].Estado)
            this.setState({venderConListaPrecios: data[0].Estado})
        } catch (error) {
            this.setState({error: error});
        }
    }

    getRUSS = async () => {
        this.setState({error: null});
        try {
            const response = await fetch(`/api/preVentas/getEmpresaByUser`);
            const data = await response.json();
            this.setState({RUSS: data[0].EsRuss})
        } catch (error) {
            this.setState({error: error});
        }
    }

    async getPreventaById(IdPreventa) {
        this.setState({error: null});
        try {
            const response = await fetch(
                `/api/gestionPreventas/preventa/${IdPreventa}`
            );
            const preventa = await response.json();

            if (preventa.respuesta[0].Estado === ESTADO_PREVENTA.PROCESADA || preventa.respuesta[0].Estado === ESTADO_PREVENTA.CANCELADA)
                return this.props.history.push(`/gestionpreventas`);


            this.setState({
                IdPreventa: preventa.respuesta[0].IdPreventa,
                Total: preventa.respuesta[0].Total,
                IdCliente: preventa.respuesta[0].IdCliente,
                IdEmpresa: preventa.respuesta[0].IdEmpresa,
                Estado: preventa.respuesta[0].Estado,
                TasaICBPER: preventa.respuesta[0].TasaICBPER,
                TasaIGV: preventa.respuesta[0].TasaIGV,
                Alias: preventa.respuesta[0].Alias,
                Documento: preventa.respuesta[0].NroTipoDocumento,
                IdTipoDocumentoSunat: preventa.respuesta[0].IdTipoDocumentoSunat
            })
        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }

    }

    async getAlmacenes() {
        this.setState({error: null});
        try {
            const response = await fetch("/api/almacenes/AlmacenBySucursal");
            const data = await response.json();
            this.setState({
                almacenes: data,
                IdAlmacen: data.results[0].IdAlmacen
            });
        } catch (error) {
            this.setState({loading: false, error: error});
        }
    }

    async getDetallePreventa(IdPreventa) {
        try {
            const response = await fetch(`/api/gestionPreventas/detallepreventa/${IdPreventa}`);
            const data = await response.json();
            this.setState({
                loading: false,
            });
            this.MostrarListaDetalle(data.respuesta)
        } catch (error) {
            this.setState({loading: false, error: error});
        }
    }

    MostrarListaDetalle = async (detalles) => {
        let detsProd = detalles.map(dp => ({
            ...dp,
            mensaje: "",
            IdTipoStock: dp.IdTipoStock,
            IdPrecioPlantilla: dp.IdPrecioPlantilla,
            Simbolo: dp.Simbolo,
            Tributos: _.isString(dp.Tributos) ? JSON.parse(dp.Tributos) : dp.Tributos,
            TasaISC: dp.TasaISC,
            PrecioEspecial: FindPrecioEspecial([], dp),
            PrecioFamiliar: FindPrecioFamiliar([], dp),
            PrecioCosto: GetPrecioCosto([], dp),
            PrecioMenor: FindPrecioMenor([], dp),
            checked: dp.Gratuito === 1,
            IdUnidad: dp.IdUnidad,
            oldPrecios: [],
            oldCants: [dp.Cantidad]
        }))
        this.setState({detalles: detsProd})
        this.montoTotalDetalle(detsProd)
    }

    montoTotalDetalle(items) {
        const itemsTmp = [...items];
        const totales = calcularTotales(itemsTmp);
        this.setState({
            Gravado: totales.gravados,
            Inafecto: decimalAdjust('floor', totales.inafectos, -2),
            Exonerado: decimalAdjust('floor', totales.exonerados, -2),
            IGVPreventa: totales.gravados * 0.18,
            Gratuitas: decimalAdjust('floor', totales.gratuitos, -2),
            totalMonto: totales.totalRedondeado,
            ICBPERPreventa: decimalAdjust('floor', totales.icbper, -2),
            redondeo: totales.redondeo
        })
    }

    async ActualizarPreventaMontoInDB() {
        try {
            let res = await fetch(`/api/preVentas/PreventaUpdateMonto/${this.state.IdPreventa}`, {
                method: "PUT",
                body: JSON.stringify({
                    Total: this.state.totalMonto
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "entity": ENTITY.PREVENTA,
                    "action": ACTION.UPDATE,
                    "entityId": this.state.IdPreventa,
                    "accessCode": this.state.accessCode,
                }
            })
            if (res.status === 403)
                notificarError("No tiene permiso.")
            if (res.status !== 401 && res.status > 400)
                notificarError("Ha ocurrido un error.")
        } catch (error) {
            this.setState({error: error});
        }
    }

    getProductos = async (idTipoStock = TIPO_STOCK.CON_COMPROBANTE, query = '') => {
        this.setState({loading: true, error: null});
        try {
            const response = await fetch(`/api/preVentas/?search=${query}&idTipoStock=${idTipoStock}`);
            const productos = await response.json();
            this.setState({
                loading: false,
                dataProductos: productos,
            });
            await this.TributosInProductos(this.state.dataProductos.productos)
        } catch (error) {
            this.setState({loading: false, error: error});
            console.log("getProductos error: ", error)
        }
    }

    TraerClientePorDoc = async (DNI) => {
        try {
            await fetch(`/api/preVentas/traerCliente/${DNI}`)
                .then(res => res.json())
                .then(data => {
                    this.setState({
                        dataCliente: data[0] ? data[0] : 0,
                        IdCliente: data[0] ? data[0].IdCliente : 0,
                        StockPorCliente: []
                    });
                    if (data[0]) {
                        this.ActualizarPreventaClienteInDB(data[0].IdCliente)
                    }
                });
        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    }

    ActualizarPreventaClienteInDB = async (IdCliente) => {
        try {
            await fetch(`/api/preVentas/PreventaUpdateCliente/${this.state.IdPreventa}`, {
                method: "PUT",
                body: JSON.stringify({
                    IdCliente: IdCliente
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "entity": ENTITY.PREVENTA,
                    "action": ACTION.UPDATE,
                    "entityId": this.state.IdPreventa,
                    "accessCode": this.state.accessCode,
                }
            })
                .then(res => res.json())
                .then(data => console.log(data))
                .catch(err => {
                })
        } catch (error) {
            this.setState({error: error});
        }
    }

    TributosInProductos = async (productos) => {
        for (let i = 0; i < productos.length; i++) {
            const itemCalculado = setPreciosYDescuento(productos[i])
            productos[i].Precio = itemCalculado.PrecioVenta
            productos[i].Gratuito = parseInt(productos[i].Gratuito)
        }
        this.setState(this.state.dataProductos)
    }

    BotonTipoLista = (tipoLista, NombreTipoLista) => {
        return (
            <React.Fragment>
                <button
                    type="button"
                    className={
                        this.state.tipoLista === tipoLista
                            ? "preventa__tipo--comprobante--item--active"
                            : "preventa__tipo--comprobante--item"
                    }
                    value={this.state.tipoLista}
                    onClick={() => this.handleTipoLista(tipoLista)}
                >
                    {NombreTipoLista}
                </button>
            </React.Fragment>
        )

    }

    getBotonTipoComprobante = (IdTipoDocumentoSunat, NombreDocSunat) => {
        if (IdTipoDocumentoSunat !== FACTURA) {
            return (
                <React.Fragment>
                    <button
                        type="button"
                        className={
                            this.state.IdTipoDocumentoSunat === IdTipoDocumentoSunat
                                ? "preventa__tipo--comprobante--item--active"
                                : "preventa__tipo--comprobante--item"
                        }
                        value={this.state.IdTipoDocumentoSunat}
                        onClick={() => this.handleTipoComprobante(IdTipoDocumentoSunat)}
                    >
                        {NombreDocSunat}
                    </button>
                </React.Fragment>
            )
        } else {
            if (this.state.RUSS === "Inactivo") {
                return (
                    <React.Fragment>
                        <button
                            type="button"
                            className={
                                this.state.IdTipoDocumentoSunat === IdTipoDocumentoSunat
                                    ? "preventa__tipo--comprobante--item--active"
                                    : "preventa__tipo--comprobante--item"
                            }
                            value={this.state.IdTipoDocumentoSunat}
                            onClick={() => this.handleTipoComprobante(IdTipoDocumentoSunat)}
                        >
                            {NombreDocSunat}
                        </button>
                    </React.Fragment>
                )
            }
        }
    }

    handleOpenModal() {
        this.setState({
            statusModal: true
        });
    }

    async handleOpenModalDescuento(item, codigoValidated = false) {
        const permisoDescuento = this.state.permisosDescuento;
        const codigoValido = codigoValidated ? codigoValidated : this.state.codigoValido;

        this.setState({
            load: true,
            ItemDescuento: item
        })

        await this.setAfectsIgv(item.IdGrupoClasificacionIgv)

        if (permisoDescuento && codigoValido || permisoDescuento == false) {
            this.setState({
                load: false,
                statusModalDescuento: true,
            })

            return;
        }

        this.setState({
            load: false
        })

        this.openModalValidacion();
    }

    async setAfectsIgv(idAfect) {
        let res = await fetch(`/api/preVentas/free-afects?idGrupoIgv=${idAfect}`)
        if (res.ok)
            this.setState({afectsIgv: await res.json()})
    }

    handleOnCloseModal() {
        this.setState({
            statusModal: false
        });
    }

    handleOnCloseModalDescuento() {
        this.setState({
            statusModalDescuento: false
        })
    }

    handleKeyUp(event) {
        if (event.key === "Escape" || event.key === "Enter") {
            this.setState({
                statusModal: false,
                statusModalDescuento: false
            });
        }
    }

    AlmacenChange(e) {
        this.setState({
            IdAlmacen: e.target.value
        });
    }

    handleClickNuevo = () => {
        this.setState({
            modalClienteIsOpen: true,
            itemcliente: {
                ...this.state.itemcliente,
                idcliente: null,
            }
        })
    }

    handleCloseModal = () => {
        this.setState({modalClienteIsOpen: false})
    }

    handleClickBuscar = () => {
        this.setState({
            statusModalSearchCliente: true
        })
    }

    GetCliente = (cliente) => {
        this.handleCloseBuscar()
        this.setState({
            Documento: cliente.NroTipoDocumento
        })
        this.TraerClientePorDoc(cliente.NroTipoDocumento)
            .then(async () => {
                if (this.state.RUSS === "Inactivo") {

                    if (this.state.IdTipoDocumentoSunat !== VALE) {
                        if (this.state.dataCliente.IdTipoDocumento === 1 || this.state.dataCliente.IdTipoDocumento === 7) {
                            let ok = await this.updateTipoDoc(BOLETA)
                            this.setState({
                                IdTipoDocumentoSunat: ok ? BOLETA : this.state.IdTipoDocumentoSunat,
                                filtroTipoStock: 1,
                                checkfiltroTipoStock: true
                            })
                        } else {
                            let ok = await this.updateTipoDoc(FACTURA)
                            this.setState({
                                IdTipoDocumentoSunat: ok ? FACTURA : this.state.IdTipoDocumentoSunat,
                                filtroTipoStock: 1,
                                checkfiltroTipoStock: true
                            })
                        }
                    }

                }
            })
    }

    handleCloseBuscar = () => {
        this.setState({
            statusModalSearchCliente: false
        })
    }

    setActiveInputDoc = (e, inputName) => {
        e.target.select()
        this.setState({
            inputName: inputName
        });
    }

    DocChange = (e) => {

        this.setState({
            Documento: e.target.value
        }, () => {
            if (this.state.Documento.length == 8 ||
                this.state.Documento.length == 11 ||
                this.state.Documento.length == 9) {
                this.TraerClientePorDoc(this.state.Documento)
                    .then(() => {
                        if (this.state.RUSS == "Inactivo") {
                            if (this.state.dataCliente.IdTipoDocumento === 1 || this.state.dataCliente.IdTipoDocumento === 7) {
                                this.setState({
                                    IdTipoDocumentoSunat: BOLETA,
                                    filtroTipoStock: 1,
                                    checkfiltroTipoStock: true
                                })
                            } else {
                                this.setState({
                                    IdTipoDocumentoSunat: FACTURA,
                                    filtroTipoStock: 1,
                                    checkfiltroTipoStock: true
                                })
                            }
                        }
                    })
                if (this.state.Documento.length == 8 || this.state.Documento.length == 9) {
                    this.handleTipoComprobante(4)
                }
            }
        });
    }

    AsignarValorDoc(dataCliente) {
        this.setState({
            Documento: dataCliente.NroTipoDocumento
        })
        this.TraerClientePorDoc(dataCliente.NroTipoDocumento)
    }

    AliasChange = e => {
        this.setState({
            Alias: e.target.value
        });
    }

    handleChange = (e) => {
        this.setState({
            buscar: e.target.value
        });
    }

    handleTipoLista(tipoLista) {
        this.setState({
            tipoLista: tipoLista
        })
    }

    async handleTipoComprobante(IdTipoDocumentoSunat) {
        /******FACTURA SE PUEDE CON RUC, BOLETA/VALE CON AMBOS********* */
        let prodVale = this.state.detalles.filter((detalle) => {
            return (
                detalle.IdTipoStock === 2
            )
        })

        switch (IdTipoDocumentoSunat) {
            case 2:
                if (prodVale.length > 0) {
                    notificationProdValeInDetalle()
                } else {
                    if (this.state.Documento.length === 11 && this.state.IdCliente != 0) {
                        let ok = await this.updateTipoDoc(IdTipoDocumentoSunat);
                        this.setState({
                            IdTipoDocumentoSunat: ok ? IdTipoDocumentoSunat : this.state.IdTipoDocumentoSunat,
                            filtroTipoStock: 1,
                            checkfiltroTipoStock: true
                        });
                        this.getProductos(TIPO_STOCK.CON_COMPROBANTE, this.state.buscar)
                    } else {
                        notificationFacturaRUC()
                    }
                }

                break;
            case 4:
                if (prodVale.length > 0) {
                    notificationProdValeInDetalle()
                } else {
                    let ok = await this.updateTipoDoc(IdTipoDocumentoSunat);
                    this.setState({
                        IdTipoDocumentoSunat: ok ? IdTipoDocumentoSunat : this.state.IdTipoDocumentoSunat,
                        filtroTipoStock: 1,
                        checkfiltroTipoStock: true
                    });
                    this.getProductos(TIPO_STOCK.CON_COMPROBANTE, this.state.buscar)
                }
                break;
            default:
                let ok = await this.updateTipoDoc(IdTipoDocumentoSunat);
                this.setState({
                    IdTipoDocumentoSunat: ok ? IdTipoDocumentoSunat : this.state.IdTipoDocumentoSunat,
                });
                break;
        }
    }

    onChangeCheckBox = async e => {
        const {name, checked} = e.target;
        if (this.state.IdTipoDocumentoSunat === VALE) {

            let tmpDets = [...this.state.detalles];
            this.setState({load: true})
            for (let i = 0; i < tmpDets.length; i++)
                await this.removeDetVenta(tmpDets[i])

            this.setState({[name]: 2, checkfiltroTipoStock: checked, load: false})
            if (checked)
                this.getProductos(TIPO_STOCK.CON_COMPROBANTE, this.state.buscar)
            else
                this.getProductos(TIPO_STOCK.SIN_COMPROBANTE, this.state.buscar)
        } else {
            this.setState({[name]: 1})
            notificationStockSinComprobante()
        }
    }

    onChangeAfectGratuita = async (e, item) => {
        this.updateGratuitoWrapper(e.target.value, item);
    }

    async updateGratuitoWrapper(value, item) {
        this.setState({load: true});
        const tipoStock = this.state.checkfiltroTipoStock ? TIPO_STOCK.CON_COMPROBANTE : TIPO_STOCK.SIN_COMPROBANTE;

        let items = [...this.state.detalles];
        let i = items.indexOf(item);

        const checked = String(value) !== "-1";
        item.checked = checked
        item.Gratuito = checked ? 1 : 0

        if (checked) {
            item.Descuento = 0
            item.IdAfectacionIgv = value
        } else
            item.IdAfectacionIgv = item.initialAfectGrat

        items[i] = item;
        this.setState({detalles: items})
        await this.ActualizarDetallePreventa(item)
        await this.montoTotalDetalle(items)
        await this.ActualizarPreventaMontoInDB();
        this.getProductos(tipoStock, this.state.buscar);
        this.setState({load: false});
    }

    AddProductoInList = async (item, descuentoPredefinido = null, cantidad = 1) => {
        const tipoStock = this.state.checkfiltroTipoStock ? TIPO_STOCK.CON_COMPROBANTE : TIPO_STOCK.SIN_COMPROBANTE;
        this.setState({load: true});

        let detalles = [...this.state.detalles];
        let tmpDetails = [];
        let itemFounded = detalles.find(i => (i.IdPresentacion === item.IdPresentacion));
        const Stock = await this.getStock(item, itemFounded ? itemFounded.Cantidad + 1 : 1);

        if (this.state.VenderConStock && Stock < 0) {
            notificationStock0(item)
            this.setState({load: false});
            return;
        }

        if (itemFounded) {
            const oldQuantity = itemFounded.Cantidad;
            const oldDescuento = itemFounded.Descuento;
            const perUnitDescuento = oldDescuento / oldQuantity;
            itemFounded.Cantidad = itemFounded.Cantidad + cantidad;
            itemFounded.Descuento = perUnitDescuento * itemFounded.Cantidad;
            FindPrecioPorMayor([], itemFounded)
            itemFounded.Total = calcTotal(itemFounded) - itemFounded.Descuento;
            itemFounded.oldCants.push(itemFounded.Cantidad)
        } else {
            tmpDetails = detalles.map(dp => ({
                ...dp,
                Cantidad: dp.Cantidad,
                PrecioVenta: dp.PrecioVenta,
                Total: dp.Total,
                IdDetallePreventa: dp.IdDetallePreventa,
                Tributos: dp.Tributos,
                PrecioEspecial: FindPrecioEspecial([], dp),
                PrecioFamiliar: FindPrecioFamiliar([], dp),
                PrecioCosto: GetPrecioCosto([], dp),
                PrecioMenor: FindPrecioMenor([], dp),
                precioMayor: getPrecioPorMayor([], dp),
                checked: _.isNumber(dp.checked) ? dp.checked : 0,
                oldPrecios: dp.oldPrecios,
                initialAfectGrat: item.IdAfectacionIgv
            }))
            FindPrecioPorMayor([], item)

            const detail = {
                ...item,
                Cantidad: cantidad,
                Descuento: !!descuentoPredefinido ? descuentoPredefinido * cantidad : item.Descuento,
                PrecioVenta: item.PrecioVenta,
                Total: item.PrecioVenta,
                IdDetallePreventa: "",
                Tributos: item.Tributos,
                PrecioEspecial: FindPrecioEspecial([], item),
                PrecioFamiliar: FindPrecioFamiliar([], item),
                PrecioCosto: GetPrecioCosto([], item),
                PrecioMenor: FindPrecioMenor([], item),
                precioMayor: getPrecioPorMayor([], item),
                checked: _.isNumber(item.checked) ? item.checked : 0,
                oldPrecios: [item.PrecioVenta],
                initialAfectGrat: item.IdAfectacionIgv,
                oldCants: [1]
            }

            detail.Total = calcTotal(detail) - detail.Descuento

            const detailHandler = {
                set(target, property, value) {
                    if (property == 'Cantidad') {
                        const cantidad = +value;
                        let perUnitDescuento = 'DescuentoGuardado' in target ? target['DescuentoGuardado'] : 0;

                        if (cantidad > 0) {
                            const oldQuantity = target.Cantidad;

                            if (oldQuantity > 0) {
                                const oldDescuento = target.Descuento;
                                perUnitDescuento = oldDescuento / oldQuantity;
                            }
                        }

                        target[property] = cantidad;
                        target["Descuento"] = cantidad * perUnitDescuento;
                        target['Total'] = calcTotal(target, false, 'cantidad', 'precioVenta') - target['Descuento'];
                    } else {
                        target[property] = value;
                    }

                    return true;
                }
            }

            const detailProxy = new Proxy(detail, detailHandler)
            tmpDetails.push(detailProxy)
        }

        let dets = itemFounded ? detalles : tmpDetails;
        let targetItem = itemFounded ? itemFounded : tmpDetails[tmpDetails.length - 1];
        if (this.state.magellanListenerIsAvailable && targetItem.unidadMedida === KILOGRAMO_UNIT_DESCRIPTION ) {
            MagellanListener.requestWeight(targetItem.IdProducto, !!itemFounded);
        }

        if (itemFounded)
            await this.ActualizarStockComprometidoPreventa(targetItem, 1)
        else
            await this.AddDetallePreVenta(targetItem)

        await this.ActualizarPreventaMontoInDB();
        await this.ActualizarDetallePreventa(targetItem)
        await this.montoTotalDetalle(dets)

        this.setState({detalles: dets, load: false, buscar: this.state.cleanSearch ? '' : this.state.buscar, searchDisabled: false,})
        
        $("#InputBuscarProd").focus();

        if (!itemFounded) {
            if (descuentoPredefinido == null) {
                this.verifyPredefinedDiscount(targetItem);
            }
        }

        this.getProductos(tipoStock, this.state.buscar)
    }

    async updateTipoDoc(idTipoDoc) {
        this.setState({load: true})
        let res = await fetch(`/api/preVentas/update-tipo-doc?idTipoDoc=${idTipoDoc}&idVenta=${this.state.IdPreventa}`, {method: "PUT"})
        if (!res.ok)
            notificarError("No se pudo actualizar el tipo de doc")
        this.setState({load: false})
        return res.ok;
    }

    async RemoveProductoInList(item) {
        this.setState({load: true});
        const tipoStock = this.state.checkfiltroTipoStock ? TIPO_STOCK.CON_COMPROBANTE : TIPO_STOCK.SIN_COMPROBANTE;
        await this.removeDetVenta(item)
        this.getProductos(tipoStock, this.state.buscar);
        this.setState({load: false});
    }

    async removeDetVenta(item) {
        let detsVenta = [...this.state.detalles];
        detsVenta.splice(detsVenta.indexOf(item), 1)
        this.setState({detalles: detsVenta})

        this.montoTotalDetalle(detsVenta)
        await this.ActualizarPreventaMontoInDB();
        await this.RemoveDetallePreVenta(item)
        await this.ActualizarStockComprometidoPreventa(item, 0 - item.Cantidad)
    }

    async AgregarCantidadDet(item) {
        this.setState({load: true});
        const items = [...this.state.detalles]
        const i = items.indexOf(item)
        const tipoStock = this.state.checkfiltroTipoStock ? TIPO_STOCK.CON_COMPROBANTE : TIPO_STOCK.SIN_COMPROBANTE;
        const Stock = await this.getStock(items[i], Number(items[i].Cantidad) + 1);

        if (this.state.VenderConStock && Stock < 0) {
            notificationStock0(item)
            this.setState({load: false});
            return;
        }

        const oldQuantity = items[i].Cantidad;
        const oldDescuento = items[i].Descuento;
        const perUnitDescuento = oldDescuento / oldQuantity;
        items[i].Cantidad++;
        items[i].Descuento = perUnitDescuento * items[i].Cantidad;
        items[i].oldCants.push(items[i].Cantidad)
        FindPrecioPorMayor([], items[i])
        items[i].Total = calcTotal(items[i]) - items[i].Descuento
        this.setState({detalles: items})
        this.montoTotalDetalle(items)
        await this.ActualizarPreventaMontoInDB();
        await this.ActualizarDetallePreventa(item)
        await this.ActualizarStockComprometidoPreventa(item, 1)
        this.getProductos(tipoStock, this.state.buscar);

        this.setState({load: false});
    }

    async RestarCantidadDet(item) {
        this.setState({load: true});
        const tipoStock = this.state.checkfiltroTipoStock ? TIPO_STOCK.CON_COMPROBANTE : TIPO_STOCK.SIN_COMPROBANTE;

        const items = [...this.state.detalles]
        const i = items.indexOf(item)

        if (items[i].Cantidad > 1) {
            const oldQuantity = items[i].Cantidad;
            const oldDescuento = items[i].Descuento;
            const perUnitDescuento = oldDescuento / oldQuantity;

            items[i].Cantidad--;
            items[i].Descuento = perUnitDescuento * items[i].Cantidad;
            items[i].oldCants.push(items[i].Cantidad)
            FindPrecioPorMayor([], items[i])
            items[i].Total = calcTotal(items[i]) - items[i].Descuento

            this.setState({detalles: items})
            await this.montoTotalDetalle(items)
            await this.ActualizarPreventaMontoInDB();
            await this.ActualizarDetallePreventa(item)
            await this.ActualizarStockComprometidoPreventa(item, -1)
            this.getProductos(tipoStock, this.state.buscar);
        }
        this.setState({load: false});
    }

    ActualizarStockComprometidoPreventa = async (item, variacionStock, setAll = false) => {
        const idStock = item.IdTipoStock === TIPO_STOCK.CON_COMPROBANTE ?
            item.stocks.find(i => i.IdTipoStock === TIPO_STOCK.CON_COMPROBANTE_PREVENTA).IdStock :
            item.stocks.find(i => i.IdTipoStock === TIPO_STOCK.SIN_COMPROBANTE_PREVENTA).IdStock;

        try {
            let res = await fetch(`/api/preVentas/editStockCompromPreV/${idStock}?idPreventa=${this.state.IdPreventa}&${setAll ? "setAll=1" : ""}`, {
                method: "PUT",
                body: JSON.stringify({
                    variacionStock: variacionStock * item.TipoCambio
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "entity": ENTITY.PREVENTA,
                    "action": ACTION.UPDATE,
                    "entityId": this.state.IdPreventa,
                    "accessCode": this.state.accessCode,
                }
            })
            if (res.status === 403)
                notificarError("No tiene permiso.")
            if (res.status !== 401 && res.status > 400)
                notificarError("Ha ocurrido un error.")

        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
            console.log(error)
        }
    }

    PrecioChange = async (e, item) => {
        this.findPrecioUnitario(item, e.target.value, e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text)
    }

    async findPrecioUnitario(item, value, precioSelectedText) {
        this.setState({load: true});
        await this.selectPrecioChange(item, value, precioSelectedText)
        this.setState({load: false});
    }

    async selectPrecioChange(item, value, precioSelectedText) {
        const tipoStock = this.state.checkfiltroTipoStock ? TIPO_STOCK.CON_COMPROBANTE : TIPO_STOCK.SIN_COMPROBANTE;

        const listaItems = [...this.state.detalles];
        const key = listaItems.indexOf(item)
        let newItem = listaItems[key];
        const precioSelected = precioSelectedText
        const precio = item.precios.find(p => p.NombrePrecio.includes(precioSelected));

        newItem.Precio = precio ? precio.Precio : 0
        newItem.PrecioVenta = precio ? precio.Precio : 0
        newItem.ValorUnitario = precio ? precio.ValorUnitario : 0;
        newItem.Total = calcTotal({Cantidad: listaItems[key].Cantidad, PrecioVenta: newItem.PrecioVenta})
        newItem.PrecioReferencial = parseFloat(newItem.PrecioVenta)

        listaItems[key] = newItem;

        this.setState({
            detalles: listaItems
        })

        await this.montoTotalDetalle(listaItems)
        await this.ActualizarPreventaMontoInDB();
        await this.ActualizarDetallePreventa(listaItems[key])
        this.getProductos(tipoStock, this.state.buscar);
    }

    InputCantidadDetalleChange = async (event, item) => {
        const items = [...this.state.detalles]
        const i = items.indexOf(item)
        const {value} = event.target;
        if (value >= 0) {
            items[i].Cantidad = items[i].IdUnidad === 58 || items[i].IdUnidad === 59 ? Math.floor(value) : value;
            FindPrecioPorMayor([], items[i])
            items[i].Total = calcTotal(items[i]) - items[i].Descuento
            this.setState({detalles: items});
            this.montoTotalDetalle(items);
            await this.ActualizarDetallePreventa(items[i])
        } else
            notificationCantidadNegativa()
    };

    async onBlurInputPrecio(e, item) {
        const {value} = e.target;
        if (value < item.PrecioCosto) {
            new Noty({
                type: "warning",
                theme: "bootstrap-v4",
                layout: "topCenter",
                text: `El precio ingresado es menor al precio de costo`,
                timeout: 1500
            }).show()
            let precio = item.oldPrecios.reverse().find(g => Number(g) >= Number(item.PrecioCosto));
            this.precioVentaChange(precio || item.PrecioCosto, item)
        } else {
            this.setState({load: true});
            const tipoStock = this.state.checkfiltroTipoStock ? TIPO_STOCK.CON_COMPROBANTE : TIPO_STOCK.SIN_COMPROBANTE;
            this.montoTotalDetalle(this.state.detalles)
            await this.ActualizarPreventaMontoInDB();
            await this.ActualizarDetallePreventa(item);
            this.getProductos(tipoStock, this.state.buscar);
            this.setState({load: false});
        }
    }

    InputPrecioVentaChange = (event, item) => {
        this.precioVentaChange(event.target.value, item);
    }

    precioVentaChange(val, item) {
        const listaItems = [...this.state.detalles]
        const key = listaItems.indexOf(item)
        const value = val;
        const itemcalculo = this.getItemCalculo(item);

        listaItems[key].oldPrecios.push(listaItems[key].PrecioVenta);
        listaItems[key].PrecioVenta = value
        listaItems[key].ValorUnitario = setValorUnitario(itemcalculo)
        listaItems[key].Total = calcTotal(listaItems[key]) - listaItems[key].Descuento
        this.setState({detalles: listaItems})
    }

    getItemCalculo(item) {
        return {
            "PrecioVenta": item.PrecioVenta,
            "Tributos": item.Tributos,
            "TasaISC": item.TasaISC,
            "Cantidad": item.Cantidad,
            "Gratuito": item.Gratuito,
            "Descuento": item.Descuento
        }
    }

    DescuentoChange = async (event, item, save = false) => {
        const listaItems = [...this.state.detalles]
        const key = listaItems.indexOf(item)
        const value = isNumber(event.target.value) ? event.target.value : 0;

        if (Number(listaItems[key].Gratuito) === 0) {
            if (save) {
                listaItems[key].DescuentoGuardado = value;
            }

            if (value <= listaItems[key].Total) {
                listaItems[key].Descuento = value
                if (save) {
                    listaItems[key].DescuentoGuardado = value;
                }
                listaItems[key].Total = calcTotal(listaItems[key]) - listaItems[key].Descuento
                this.setState({detalles: listaItems, ItemDescuento: listaItems[key]})
                await this.montoTotalDetalle(listaItems)
            } else
                notificationDescuentoMayorTotal()
        } else
            notificationDescuentoGratuito()
    }

    async onBlurDescuento(e, item) {
        this.setState({load: true});
        const tipoStock = this.state.checkfiltroTipoStock ? TIPO_STOCK.CON_COMPROBANTE : TIPO_STOCK.SIN_COMPROBANTE;
        await this.montoTotalDetalle(this.state.detalles)
        await this.ActualizarPreventaMontoInDB();
        await this.ActualizarDetallePreventa(item)
        this.getProductos(tipoStock, this.state.buscar);
        this.setState({load: false});
    }

    AddDetallePreVenta = async (item) => {
        this.setState({error: null});
        try {
            let res = await fetch("/api/preVentas/addDetalle", {
                method: "POST",
                body: JSON.stringify({
                    IdPreventa: this.state.IdPreventa,
                    Cantidad: item.Cantidad,
                    Total: item.Total,
                    IdStock: item.IdStock,
                    IdPresentacion: item.IdPresentacion,
                    IdAfectacionIgv: item.IdAfectacionIgv,
                    Descuento: 0,
                    PrecioReferencial: item.Precio,
                    PrecioVenta: item.PrecioVenta,
                    TipoCambio: item.TipoCambio,
                    IdAlmacen: item.IdAlmacen,
                    ValorUnitario: item.ValorUnitario
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "entity": ENTITY.PREVENTA,
                    "action": ACTION.UPDATE,
                    "entityId": this.state.IdPreventa,
                    "accessCode": this.state.accessCode,
                }
            })
            if (res.status === 403)
                notificarError("No tiene permiso.")
            if (res.status !== 401 && res.status > 400)
                notificarError("Ha ocurrido un error.")
            else {
                let json = await res.json();
                if (json.status) {
                    item.IdDetallePreventa = json.respuesta;
                    let detsItem = [...this.state.detalles];
                    let index = detsItem.indexOf(detsItem);
                    detsItem[index] = item
                    this.setState({detalles: detsItem})
                    await this.ActualizarStockComprometidoPreventa(item, 1)
                } else
                    await this.generarOtraVenta(this.state.IdPreventa);
            }

        } catch (error) {
            this.setState({
                loading: false,
                error: error
            });
        }
    }

    RemoveDetallePreVenta = async item => {
        this.setState({error: null});
        try {
            let res = await fetch(`/api/preVentas/deleteDetPreVenta/${item.IdDetallePreventa}?idPreventa=${this.state.IdPreventa}`, {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "entity": ENTITY.PREVENTA,
                    "action": ACTION.UPDATE,
                    "entityId": this.state.IdPreventa,
                    "accessCode": this.state.accessCode,
                }
            })
            if (res.status === 403)
                notificarError("No tiene permiso.")
            if (res.status !== 401 && res.status > 400)
                notificarError("Ha ocurrido un error.")
        } catch (error) {
            this.setState({
                loading: false,
                error: error
            });
        }
    }

    ActualizarDetallePreventa = async (item) => {
        try {
            let res = await fetch(`/api/preVentas/editDet/${item.IdDetallePreventa}`, {
                method: "PUT",
                body: JSON.stringify({
                    IdPreventa: this.state.IdPreventa,
                    IdStock: item.IdStock,
                    IdPresentacion: item.IdPresentacion,
                    Cantidad: item.Cantidad,
                    Total: item.Total,
                    PrecioVenta: item.PrecioVenta,
                    Precio: item.PrecioReferencial,
                    ValorUnitario: item.ValorUnitario,
                    Descuento: item.Descuento,
                    Gratuito: item.Gratuito,
                    IdAfectacionIgv: item.IdAfectacionIgv
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "entity": ENTITY.PREVENTA,
                    "action": ACTION.UPDATE,
                    "entityId": this.state.IdPreventa,
                    "accessCode": this.state.accessCode,
                }
            })

            if (res.status === 403 || res.status === 404)
                notificarError("No tiene permiso o el recurso no existe.")
            if (res.status !== 401 && res.status > 400)
                notificarError("Ha ocurrido un error.")
        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    }

    async handleGenerarPreventa(IdPreventa) {
        let tieneProductosNoFacturables = this.tieneProductosNoFacturables(this.state.detalles);
        let tieneDetallesConCantidadCero = this.tieneDetallesConCantidadCero(this.state.detalles);
        let tipoDoc = this.state.IdTipoDocumentoSunat;
        if (tipoDoc > 1 && tieneProductosNoFacturables) {
            swal({
                title: "Alerta de venta",
                text: "Revise sus productos",
                icon: "warning",
                buttons: "Ok"
            });
        } else if (tieneDetallesConCantidadCero) {
            swal({
                title: "Alerta de venta",
                text: "Tiene ventas con cantidad 0",
                icon: "warning",
                buttons: "Ok"
            });
        } else
            await this.continueSell(IdPreventa);
    }

    async estadoVenta(idPreventa) {
        let res = await fetch(`/api/preVentas/status-preventa?idPreventa=${idPreventa}`);
        let json = await res.json();
        if (res.status === 404) {
            window.localStorage.removeItem('idPreventa');
            window.location.reload();
        }
        return json.Estado;
    }

    tieneDetallesConCantidadCero(dets) {
        return dets.filter(d => Number(d.Cantidad) === 0 || Number(d.Total) === 0).length;
    }

    tieneProductosNoFacturables(dets) {
        for (let i = 0; i < dets.length; i++)
            if (!dets[i].EsFacturable)
                return true;
        return false;
    }

    async continueSell(IdPreventa) {
        if (this.state.BoletaRuc === 1) {
            if (this.state.dataCliente.IdTipoDocumento === RUC && this.state.IdTipoDocumentoSunat === BOLETA) {
                const willGenerar = await swal({
                    title: "¿Está seguro?",
                    text: "¿Generar una Boleta con RUC?",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                    closeOnClickOutside: false
                });
                if (willGenerar) {
                    this.GenerarPreVenta(IdPreventa)
                }
            } else {
                this.GenerarPreVenta(IdPreventa)
            }
        } else {
            if (this.state.dataCliente.IdTipoDocumento === RUC && this.state.IdTipoDocumentoSunat === BOLETA) {
                notificationConfigBoletaRUC()
            } else {
                this.GenerarPreVenta(IdPreventa)
            }
        }
    }

    GenerarPreVenta = async (IdPreventa) => {
        this.setState({error: null});
        if (this.state.detalles.length === 0) {
            notificationPreventaVacia()
        } else {
            if (this.state.IdCliente !== 0) {
                if (this.state.dataCliente.NroTipoDocumento === "99999999"
                    && this.state.IdTipoDocumentoSunat === BOLETA && this.state.totalMonto >= 700) {
                    notificationBoletaVarios()
                } else {
                    try {
                        await fetch(`/api/preVentas/GenerarPreVenta/${IdPreventa}`, {
                            method: "PUT",
                            body: JSON.stringify({
                                Estado: "GENERADO",
                                IdCliente: this.state.IdCliente,
                                Total: this.state.totalMonto,
                                Alias: this.state.Alias,
                                IdTipoDocumentoSunat: this.state.IdTipoDocumentoSunat
                            }),
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                                "entity": ENTITY.PREVENTA,
                                "action": ACTION.UPDATE,
                                "entityId": this.state.IdPreventa,
                                "accessCode": this.state.accessCode,
                            }
                        })
                            .then(res => res.json())
                            .then((respuesta) => {
                                let tienePermisoCaja = window.sessionStorage.getItem('tienePermisoCaja');
                                window.sessionStorage.clear();
                                if (tienePermisoCaja === "true")
                                    this.props.history.push(`/pagos/${IdPreventa}`)
                                else {
                                    if (this.queryParams.get('disabled') === 'true') {
                                        window.location.href = '/restaurantes/'
                                    } else {
                                        this.props.history.push(`/gestionpreventas`)
                                    }
                                }

                            })
                            .catch(err => {
                                this.setState({error: "Ha ocurrido un error. Posiblemente por permisos o recursos de red"})
                            });

                    } catch (error) {
                        this.setState({
                            loading: false,
                            error: "Ha ocurrido un error. Posiblemente por permisos o recursos de red"
                        });
                    }
                }
            } else {
                notificationClienteNoExiste()
            }
        }
    }

    handleOnDeletePreventa(listaDetalle, IdPreventa) {
        return async () => {
            const willDelete = await swal({
                title: "¿Está seguro?",
                text: "¿Borrar de Preventas no Generadas?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                closeOnClickOutside: false
            });

            if (willDelete) {
                await this.CancelarPreVenta(listaDetalle)
                    .then(() => {
                        this.DeletePreventa(IdPreventa)
                    })
            }
        };
    }

    CancelarPreVenta = async (listaDetalle) => {
        for (let i = 0; i < listaDetalle.length; i++) {
            this.ActualizarStockComprometidoPreventa(listaDetalle[i], 0 - listaDetalle[i].Cantidad)
        }
    }

    DeletePreventa = async (IdPreventa) => {
        if (this.props.location.from) {
            return this.props.history.push(this.props.location.from)
        }
        try {
            fetch(`/api/preVentas/deletePreVenta/${IdPreventa}`, {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
                .then(() => this.props.history.push(`/preVentas/preventasList`))
        } catch (error) {
            this.setState({
                loading: false,
                error: error
            });
        }
    }

    async handleCodeReading() {
        const tipoStock = this.state.checkfiltroTipoStock ? TIPO_STOCK.CON_COMPROBANTE : TIPO_STOCK.SIN_COMPROBANTE;
        this.setState({searchDisabled: true})
        await this.getProductos(tipoStock, this.state.buscar);
        this.setState({searchDisabled: false})
        const productos = this.state.dataProductos.productos;
        if (productos.length === 1) {
            this.AddProductoInList(productos[0])
            this.setState({
                buscar: this.state.cleanSearch ? "" : this.state.buscar,
                searchDisabled: false
            })
        } else {
            this.setState({
                buscar: this.state.cleanSearch ? "" : this.state.buscar,
                searchDisabled: false
            })
            notificationNoHayProducto()
        }
        this.getProductos(tipoStock, this.state.buscar);
    }

    async handleEnterKeyUp(e) {
        const tipoStock = this.state.checkfiltroTipoStock ? TIPO_STOCK.CON_COMPROBANTE : TIPO_STOCK.SIN_COMPROBANTE;
        if (e.key === "Enter") {
            this.setState({searchDisabled: true})
            await this.getProductos(tipoStock, this.state.buscar);
            
            const productos = this.state.dataProductos.productos;
            if (productos.length === 1) {
                await this.AddProductoInList(productos[0])
                this.setState({
                    buscar: this.state.cleanSearch ? "" : this.state.buscar,
                })
            } else {
                this.setState({
                    buscar: this.state.cleanSearch ? "" : this.state.buscar,
                })
                notificationNoHayProducto()
            }
            this.getProductos(tipoStock, this.state.buscar);
        } else {
            this.getProductos(tipoStock, this.state.buscar);
        }

        const filteredCombos = matchSorter(this.state.combos, this.state.buscar, { keys: ["Nombre", "CodigoBarras"] });
        this.setState({ searchDisabled: false, filteredCombos });
        $("#InputBuscarProd").focus();
    }

    onSecretKeyPress(event, detItem) {
        if (event.ctrlKey && event.shiftKey && event.which === NUMBER_KEYS.THREE_NUMBER_KEY)
            this.PrecioChange(detItem, detItem.precioMayor.Precio, "Mayor")
    }

    async onBlurCantidad(e, item) {
        this.setState({load: true});
        let items = [...this.state.detalles]
        const i = items.indexOf(item)
        const value = isNumber(e.target.value) ? e.target.value : 1;
        let variacionStock = item.IdUnidad === 58 || item.IdUnidad === 59 ? parseInt(value) : value;
        const Stock = await this.getStock(item, variacionStock);
        const tipoStock = this.state.checkfiltroTipoStock ? TIPO_STOCK.CON_COMPROBANTE : TIPO_STOCK.SIN_COMPROBANTE;

        variacionStock = this.state.VenderConStock ? (Stock >= 0 ? variacionStock : 1) : variacionStock;

        if (Stock < 0 && this.state.VenderConStock) {
            notificationStock0(item)
            items[i].Cantidad = 1;
            items[i].Total = calcTotal(items[i]) - items[i].Descuento
        } else {
            items[i].Cantidad = variacionStock;
            items[i].Total = calcTotal(items[i]) - items[i].Descuento
        }

        items[i].oldCants.push(items[i].Cantidad)
        const oldCants = items[i].oldCants;
        const cantExtraccion = oldCants[oldCants.length - 1] - (oldCants[oldCants.length - 2] ?? 0)

        this.montoTotalDetalle(items)
        await this.ActualizarPreventaMontoInDB();
        await this.ActualizarDetallePreventa(items[i])
        await this.ActualizarStockComprometidoPreventa(items[i], Number(cantExtraccion))
        this.getProductos(tipoStock, this.state.buscar);
        this.setState({load: false});
    }

    async getStock(item, variacion) {
        const query = `idPres=${item.IdPresentacion}&idTipoStock=${item.IdTipoStock}&variacion=${variacion}`
        let stockRes = await fetch(`/api/preVentas/check-stock?${query}`)
        let stock = await stockRes.json();
        return stock.stock;
    }

    onChangeTotal(e, item) {
        const listaItems = [...this.state.detalles]
        const key = listaItems.indexOf(item)
        const value = isNumber(e.target.value) ? e.target.value : 0;

        listaItems[key].Cantidad = value / listaItems[key].PrecioVenta
        listaItems[key].Total = value
        listaItems[key].Descuento = 0
        this.setState({detalles: listaItems})
    }

    async onBlurTotal(e, item) {
        this.setState({load: true});
        const tipoStock = this.state.checkfiltroTipoStock ? TIPO_STOCK.CON_COMPROBANTE : TIPO_STOCK.SIN_COMPROBANTE;
        let items = [...this.state.detalles]
        const i = items.indexOf(item)

        const value = isNumber(items[i].Cantidad) ? items[i].Cantidad : 1;

        let variacionStock = items[i].IdUnidad === 58 || items[i].IdUnidad === 59 ? parseInt(value) : value;
        const Stock = await this.getStock(items[i], variacionStock);
        variacionStock = this.state.VenderConStock ? (Stock >= 0 ? variacionStock : 1) : variacionStock;

        if (Stock < 0 && this.state.VenderConStock) {
            notificationStock0(item)
            items[i].Cantidad = 1;
            items[i].Total = calcTotal(items[i]) - items[i].Descuento
        } else
            items[i].Cantidad = variacionStock;

        items[i].oldCants.push(items[i].Cantidad)
        const oldCants = items[i].oldCants;
        const cantExtraccion = oldCants[oldCants.length - 1] - (oldCants[oldCants.length - 2] ?? 0)

        await this.montoTotalDetalle(items)
        await this.ActualizarPreventaMontoInDB();
        await this.ActualizarDetallePreventa(items[i]);
        await this.ActualizarStockComprometidoPreventa(items[i], Number(cantExtraccion))
        this.setState({load: false});
        this.getProductos(tipoStock, this.state.buscar);
    }

    render() {
        const productos = this.state.dataProductos.productos;

        if (this.state.error)
            return `Error: ${this.state.error.message}!!`;

        return (
            <React.Fragment>
                <div className="preventa__container vh-100 mh-100">
                    <div className="preventa responsive-height">

                        <div className="encabezado h-100 mh-100 overflow-hidden">
                            <div className="preventa__tipo--comprobante">
                                {this.getBotonTipoComprobante(FACTURA, "Factura")}
                                {this.getBotonTipoComprobante(BOLETA, "Boleta")}
                                {this.getBotonTipoComprobante(VALE, "Vale")}
                            </div>

                            <BuscarClientePorDoc
                                goBack={this.props.history.goBack}
                                DocChange={this.DocChange}
                                Documento={this.state.Documento}
                                setActiveInputDoc={this.setActiveInputDoc}
                                TraerClientePorDoc={this.TraerClientePorDoc}
                                dataCliente={this.state.dataCliente}
                                handleClickNuevo={this.handleClickNuevo}
                                handleClickBuscar={this.handleClickBuscar}
                                handleCloseBuscar={this.handleCloseBuscar}
                                Alias={this.state.Alias}
                                AliasChange={this.AliasChange}
                            />

                            <div className="PreVBuscarProducto mb-3">

                                <input
                                    id="InputBuscarProd"
                                    type="text"
                                    placeholder="Buscar Producto"
                                    className=" mr-2"
                                    style={{ width: "100%", height: "47px", boxShadow: "none", borderRadius: "15px 15px 15px 15px", paddingTop: "10px", paddingBottom: "10px", border:"none" }}
                                    onKeyUp={this.handleEnterKeyUp}
                                    disabled={this.state.searchDisabled}
                                    value={this.state.buscar}
                                    onChange={this.handleChange}
                                    autoFocus/>

                                <div className="row stock-box">
                                    <label className="col-6 mr-3 mt-1 stock-box-letra">Stock con Comprobante</label>
                                    <label className="col-6 col-sm-2 p-0 checkbox-togglePreventa">
                                        <div className="togglePreventa">
                                            <input
                                                type="checkbox"
                                                name="filtroTipoStock"
                                                className="toggle-state"
                                                onChange={this.onChangeCheckBox}
                                                checked={this.state.checkfiltroTipoStock}
                                            />
                                            <div className="togglePreventa-inner">
                                                <div className="indicator" />
                                            </div>
                                            <div className="active-bg" />
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className="form-group encabezadoPV h-100 flex-grow-1">
                                {/* <div className="preventa__tipo--comprobante">
                                    {this.BotonTipoLista(1, `General${this.state.loading ? " - cargando" : ""}`)}
                                </div> */}
                                <ListaProductosPreventa
                                    disableHeight={true}
                                    load={this.state.load}
                                    productos={productos}
                                    combos={this.state.buscar == "" ? [] : this.state.filteredCombos}
                                    AddProductoInList={this.AddProductoInList}
                                    checkedTipoStock={this.state.checkfiltroTipoStock}
                                />
                            </div>
                        </div>

                        <div className="preventa__container_data">
                            <div className="preventa__cliente2">
                                {
                                    (this.state.dataCliente.IdTipoDocumento) ?
                                        (
                                            (this.state.dataCliente.IdTipoDocumento === 1 || this.state.dataCliente.IdTipoDocumento === 7) ?
                                                this.state.dataCliente.ApellidoPaterno
                                                    .concat(` ${this.state.dataCliente.ApellidoMaterno} ${this.state.dataCliente.PrimerNombre}`)
                                                : this.state.dataCliente.RazonSocial
                                        )
                                        : "CLIENTE NO REGISTRADO"
                                }
                            </div>

                            <DetallesProductoPreventa
                                descuentoPorCliente={0}
                                load={this.state.load}
                                conStockChecked={this.state.checkfiltroTipoStock}
                                detalles={this.state.detalles}
                                onSecretKeyPress={this.onSecretKeyPress}
                                venderConListaPrecios={this.state.venderConListaPrecios}
                                PrecioChange={this.PrecioChange}
                                InputCantidadDetalleChange={this.InputCantidadDetalleChange}
                                InputPrecioVentaChange={this.InputPrecioVentaChange}
                                RestarCantidadDet={this.RestarCantidadDet}
                                AgregarCantidadDet={this.AgregarCantidadDet}
                                RemoveProductoInList={this.RemoveProductoInList}
                                handleOpenModalDescuento={this.handleOpenModalDescuento}
                                onBlurInputCantidad={this.onBlurCantidad}
                                onBlurPrecioVenta={this.onBlurInputPrecio}
                                canUpdatePrice={this.state.canUpdatePrice}
                                canUpdateTotal={this.state.canUpdateTotal}
                                onChangeTotal={this.onChangeTotal}
                                onBlurTotal={this.onBlurTotal}
                            />
                        </div>


                        {<ModalCliente isOpen={this.state.modalClienteIsOpen} onClose={this.handleCloseModal}
                                       title="NUEVO CLIENTE">
                            <ClienteNuevo onClose={this.handleCloseModal}
                                          NumeroDocumento={this.state.Documento}
                                          AsignarValorDoc={this.AsignarValorDoc}
                            />
                        </ModalCliente>}
                        {<Modal
                            isOpen={this.state.statusModal}
                            onClose={this.handleOnCloseModal}
                            handleKeyUp={this.handleKeyUp}
                        >
                            <PreventaAlmacenes
                                almacenes={this.state.almacenes.results}
                                IdAlmacen={this.state.IdAlmacen}
                                AlmacenChange={this.AlmacenChange}
                            />
                        </Modal>}
                        {<Modal
                            isOpen={this.state.statusModalDescuento}
                            onClose={this.handleOnCloseModalDescuento}
                            handleKeyUp={this.handleKeyUp}
                        >
                            <PreventaModalDescuentos
                                ItemDescuento={this.state.ItemDescuento}
                                DescuentoChange={this.DescuentoChange}
                                onBlurDescuento={this.onBlurDescuento}
                                afectsIgv={this.state.afectsIgv}
                                onChangeCheckBoxGratuito={this.onChangeAfectGratuita}
                            />
                        </Modal>}

                        {<Modal
                            isOpen={this.state.statusModalSearchCliente}
                            onClose={this.handleCloseBuscar}
                            handleKeyUp={this.handleKeyUp}
                        >
                            <ModalBuscarCliente
                                GetCliente={this.GetCliente}
                            />
                        </Modal>}
                    </div>

                    <div className="row totals__container ml-auto mr-auto p-0 mt-0" style={{ height: '100px'}}>
                        <div className="totals__container--item">
                            {parseFloat(this.state.Gravado) > 0 ?
                                <p>
                                    Gravado{" "}
                                    <strong>
                                        {Number(this.state.Gravado).toFixed(2)}
                                    </strong>
                                </p>
                                : null
                            }
                            {parseFloat(this.state.Inafecto) > 0 ?
                                <p>
                                    Inafecto{" "}
                                    <strong>
                                        {this.state.Inafecto}
                                    </strong>
                                </p>
                                : null
                            }
                            {parseFloat(this.state.Exonerado) > 0 ?
                                <p>
                                    Exonerado{" "}
                                    <strong>
                                        {this.state.Exonerado}
                                    </strong>
                                </p>
                                : null
                            }
                            {parseFloat(this.state.Gratuitas) > 0 ?
                                <p>
                                    Gratuitas{" "}
                                    <strong>
                                        {this.state.Gratuitas}
                                    </strong>
                                </p>
                                : null
                            }
                            {parseFloat(this.state.ICBPERPreventa) > 0 ?
                                <p>
                                    ICBPER{" "}
                                    <strong>
                                        {this.state.ICBPERPreventa}
                                    </strong>
                                </p>
                                : null
                            }
                            {parseFloat(this.state.IGVPreventa) > 0 ?
                                <p>
                                    IGV{" "}
                                    <strong>
                                        {Number(this.state.IGVPreventa).toFixed(2)}
                                    </strong>
                                </p>
                                : null
                            }

                            {parseFloat(this.state.redondeo) > 0 ?
                                <p>
                                    Redondeo <strong>
                                    {decimalAdjust('floor', this.state.redondeo, -2)}
                                </strong>
                                </p>
                                : null
                            }
                        </div>
                        <div className="totals__container--item-total">
                        <span>{this.state.totalMonto
                            .toLocaleString('en-GB', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                        </span>
                        </div>
                        <div className="totals__container--item">
                            {this.state.load ? null : (
                                <button
                                    className="generar__boton btn btn-success"
                                    type="submit"
                                    onClick={() => this.handleGenerarPreventa(this.state.IdPreventa)}
                                >
                                    {this.queryParams.get('disabled') === 'true' ? 'Actualizar' : 'Generar'}
                                </button>
                            )}
                        </div>
                    </div>
                </div>

                <ModalCliente isOpen={this.state.showModalValidacion} onClose={this.handleCloseModalValidarDescuento}
                       title="Validar Descuento de producto"
                >
                                <label className="font-weight-bold" htmlFor="inputValidacion">
                                    Codigo
                                </label>
                                <input type="password"
                                       name="inputValidacion"
                                       value={this.state.codigoValidacionInput}
                                       className="form-control mb-2"
                                       onChange={this.handleChangeCodigoAnular}/>
                    <div className="d-flex justify-content-end">
                        <button
                            className="btn btn-verde-mfp-letra-normal"
                            title="Validar"
                            onClick={this.validarCodigoUsuario}
                        >Validar
                        </button>
                    </div>
                </ModalCliente>

            </React.Fragment>
        )
    }
}

export default PreVentaEdit
