import './PreventaMobile.css'; 
import React, { useEffect, useState } from "react";
import { ProductPicker } from './ProductPicker';
import { SelectedProducts } from './SelectedProducts';
import { IdentifyClient } from './IdentifyClient';
import { FindPrecioEspecial, FindPrecioFamiliar, FindPrecioMenor, FindPrecioPorMayor, GetPrecioCosto, getPrecioPorMayor } from '../Preventas/PreciosPreventa';
import { calcTotal, calcularTotales, decimalAdjust } from '../../Global';
import { isNumber } from 'lodash';
import { notificarMsg } from '../Almacenes/AlmacenNotify';


function PreventaMobile(props) {  
  const [buscarProducto, setBuscarProducto] = useState('');
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [totales, setTotales] = useState({});
  const [client, setClient] = useState(null);
  const [alias, setAlias] = useState("");

  useEffect(() => {
    const _calculated = calcularTotales(selectedProducts);
    
    setTotales({
        Gravado: _calculated.gravados,
        Inafecto: _calculated.inafectos,
        Exonerado: _calculated.exonerados,
        IGVPreventa: _calculated.gravados * 0.18,
        Gratuitas: _calculated.gratuitos,
        totalMonto: _calculated.total,
        ICBPERPreventa: _calculated.icbper,
        redondeo: _calculated.redondeo,
    });
}, [selectedProducts]);

  const onChooseProduct = (...products) => {
    let currentItems = [...selectedProducts];

    for (const product of products) {
        const i = currentItems.indexOfObject(product, "IdPresentacion");

        if (i !== -1) {
            const oldQuantity = currentItems[i].Cantidad;
            const oldDescuento = currentItems[i].Descuento;
            const perUnitDescuento = oldDescuento / oldQuantity;

            currentItems[i].Cantidad = currentItems[i].Cantidad + ('Cantidad' in product ? product.Cantidad : 1);
            currentItems[i].Descuento = perUnitDescuento * currentItems[i].Cantidad;
            FindPrecioPorMayor([], currentItems[i])
            currentItems[i].Total = calcTotal(currentItems[i]) - currentItems[i].Descuento
        } else {
            FindPrecioPorMayor([], product);
            
            const detail = {
                ...product,
                Cantidad: product.Cantidad || 1,
                Descuento: product.Descuento * (product.Cantidad || 1),
                PrecioVenta: product.PrecioVenta,
                PrecioEspecial: FindPrecioEspecial([], product),
                PrecioFamiliar: FindPrecioFamiliar([], product),
                PrecioCosto: GetPrecioCosto([], product),
                PrecioMenor: FindPrecioMenor([], product),
                precioMayor: getPrecioPorMayor([], product),
                checked: isNumber(product.checked) ? product.checked : 0,
                oldPrecios: [product.PrecioVenta],
                initialAfectGrat: product.IdAfectacionIgv,
                oldCants: [1],
                NombreProducto: product.descripcion
            }

            detail.Total = calcTotal(detail) - detail.Descuento

            currentItems.push(detail);
        }

    }

    setSelectedProducts([...currentItems]);
  };

  const onChangeProductQuantity = (index, action) => {
    let currentItems = [...selectedProducts];

    const oldQuantity = currentItems[index].Cantidad;
    const oldDescuento = currentItems[index].Descuento;
    const perUnitDescuento = oldDescuento / oldQuantity;

    if (action == 'subtract' && oldQuantity <= 1) {
      return;
    }

    let newQuantity = 0;

    if (action == 'subtract') {
      newQuantity -= 1;
    } else if (action == 'add') {
      newQuantity += 1;
    }

    currentItems[index].Cantidad = currentItems[index].Cantidad + newQuantity;
    currentItems[index].Descuento = perUnitDescuento * currentItems[index].Cantidad;
    FindPrecioPorMayor([], currentItems[index])
    currentItems[index].Total = calcTotal(currentItems[index]) - currentItems[index].Descuento

    setSelectedProducts([...currentItems]);
  }

  const onRemoveProduct = (index) => {
    let currentItems = [...selectedProducts].filter((_, idx) => idx != index);
    setSelectedProducts([...currentItems]);
  }

  const onGenerate = async () => {
    const payload = {
      detalles: selectedProducts.map((item) => ({
          IdPresentacion: item.IdPresentacion,
          IdProducto: item.IdProducto,
          EsFacturable: item.EsFacturable,
          ValorUnitario: item.ValorUnitario,
          Gratuito: item.Gratuito,
          Descuento: item.Descuento,
          IdAfectacionIgv: item.IdAfectacionIgv,
          IdStock: item.IdStock,
          IdTipoStock: item.IdTipoStock,
          Precio: item.Precio,
          TipoCambio: item.TipoCambio,
          IdAlmacen: item.IdAlmacen,
          PrecioReferencial: item.PrecioReferencial,
          PrecioVenta: item.PrecioVenta,
          Cantidad: item.Cantidad,
          Total: item.Total,
      })),
      IdTipoDocumentoSunat: 1,
      IdCliente: client.IdCliente,
      Total: totales.totalMonto,
      alias: alias,
    };

    const req = await fetch("/api/pre-invoice/add/v2", {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json'
        }
    })

    if (!req.ok) {
        alert("Ocurrió un error al registrar el pedido")

        return;
    }

    const data = await req.json();

    notificarMsg("Se ha realizado con éxito");
    props.history.push(`/pagos/${data.IdPreventa}`)
  }

  return (
    <div>
      <div className='preventa__container '>
        <IdentifyClient onChangeClient={client => setClient(client)}/>
        <div className='ingresar_cliente'> 
          <div className='text_producto'>
              <input type="text" placeholder='ALIAS' value={alias} onChange={e => setAlias(e.target.value)}/>
          </div>
        </div>
        <div className='preventa__producto'> 
          <div className='text_producto'>
            <input type="text" placeholder='Buscar Producto' value={buscarProducto} onChange={e => setBuscarProducto(e.target.value)} />
          </div>
        </div>

      </div>
      <section className="section-left">
        <ProductPicker query={buscarProducto} onChooseProduct={onChooseProduct}/>
      </section>

        <section className="section-rigth">
          <SelectedProducts
            products={selectedProducts}
            onSubtractQuantity={index => onChangeProductQuantity(index, 'subtract')}
            onAddQuantity={index => onChangeProductQuantity(index, 'add')}
            onRemoveProduct={index => onRemoveProduct(index)}/>
        </section>
      <footer>
        <div className='footer1'>
          <div className='total__container'>
            <p>Gravado <strong> S/ {decimalAdjust("floor", totales.Gravado, -2)}</strong>
            </p>
            <p>
              Inafecto <strong> S/ {decimalAdjust("floor", totales.Inafecto, -2)}</strong>
            </p>
            <p>
              Exonerado <strong> S/ {decimalAdjust("floor", totales.Exonerado, -2)}</strong>
            </p>
            <p>
              IGV <strong> S/ {decimalAdjust("floor", totales.IGVPreventa, -2)}</strong>
            </p>
            {
              !!totales.redondeo && <p>Redondeo <strong> S/ {decimalAdjust("floor", totales.redondeo, -2)}</strong></p>
            }
          </div>
          <div className='seccion2-footer'>
            <div className='sub__total'>
                <span className='letra__total'>S/ {decimalAdjust('round', totales.totalMonto, -1)}</span>
            </div>
            <div className='continuar'>
              { (client != null && 'IdCliente' in client && selectedProducts.length ) && <span className='generar__button' onClick={onGenerate}>Generar</span> }
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default PreventaMobile;
